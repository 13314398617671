import {useEffect} from 'react';
import isEmpty from 'lodash/isEmpty';

import useGlobalNav from '@/hooks/navigation/global';
import useSignup from '@/hooks/useSignup';
import {useSharedTranslations, useTranslations} from '@/hooks/useTranslations';
import type {
  Article,
  ArticleAuthor,
  ArticleMetafield,
  Settings,
} from '@/components/blog/types';
import {BLOG_PAGE_TYPE} from '@/components/blog/types';
import {
  fetchReviewerName,
  getRightBannerButtonUrl,
} from '@/components/blog/utils';
import {BlogAuthorName} from '@/components/pages/blog/BlogAuthor/BlogAuthorName';
import {getMetafieldAuthors} from '@/pages/shopify.com/($locale)/enterprise/blog/utils/utils';

import {BlogSubscribeBanner} from '../BlogSubscribeBanner/BlogSubscribeBanner';
import {BlogSideBanner} from '../BlogSideBanner/BlogSideBanner';
import {PopularPostsBanner} from '../PopularPostsBanner/PopularPostsBanner';

import {articleAuthorStyles} from './style';

interface ContentRightColumnProps {
  popularPosts: Article[];
  enBlogPath: string;
  contentHeight: number | null;
  author?: ArticleAuthor;
  modifiedAt: string;
  settings: Settings;
  blogPageType: BLOG_PAGE_TYPE;
  metafields?: ArticleMetafield[];
  timeToRead: number | null;
  isDisabledAuthor?: boolean;
}

export const ContentRightColumn = ({
  popularPosts,
  enBlogPath,
  contentHeight,
  author,
  modifiedAt,
  settings,
  blogPageType,
  metafields,
  timeToRead,
}: ContentRightColumnProps) => {
  const {signupText} = useGlobalNav();
  const {signupUrl, signupName} = useSignup();
  const {t} = useSharedTranslations(`pages${enBlogPath}$article/content`);
  const {localizePath} = useTranslations();
  const {images, subscription} = settings;
  const reviewerName = metafields && fetchReviewerName(metafields);
  const isEnterpriseBlog = blogPageType === BLOG_PAGE_TYPE.ENTERPRISE;
  const enterpriseAuthors = getMetafieldAuthors(metafields || [], author?.name);

  const showSideBanner = ![
    BLOG_PAGE_TYPE.PARTNERS,
    BLOG_PAGE_TYPE.ENGINEERING,
  ].includes(blogPageType);
  const showSubscribeBanner = blogPageType !== BLOG_PAGE_TYPE.ENGINEERING;

  useEffect(() => {
    const blogSideBanners = document.querySelector(
      '#blog-side-banners',
    ) as HTMLElement;

    const orderByPriority = (a: HTMLElement, b: HTMLElement) => {
      if (
        (a.getAttribute('data-priority') ?? 0) <
        (b.getAttribute('data-priority') ?? 0)
      ) {
        return -1;
      } else if (
        (a.getAttribute('data-priority') ?? 0) >
        (b.getAttribute('data-priority') ?? 0)
      ) {
        return 1;
      }
      return 0;
    };

    if (blogSideBanners && contentHeight) {
      const banners = [...blogSideBanners.children] as HTMLElement[];
      const approximateBannerRenderedStep = 1000;

      const bannersCountToDisplay = Math.floor(
        contentHeight / approximateBannerRenderedStep,
      );
      const sortedBanners = banners.sort(orderByPriority);

      sortedBanners.slice(0, bannersCountToDisplay).forEach((banner) => {
        banner.classList.remove('hidden');
      });

      sortedBanners.slice(bannersCountToDisplay).forEach((banner) => {
        banner.classList.add('hidden');
      });
    }
  }, [contentHeight]);

  const sideBannerTitleStyle =
    blogPageType === BLOG_PAGE_TYPE.RETAIL
      ? 'mb-9 font-medium'
      : 'mb-4 uppercase font-semibold';

  const authorData =
    isEnterpriseBlog && enterpriseAuthors
      ? enterpriseAuthors
      : [
          {
            name: author?.name,
            prefix: isEmpty(reviewerName) ? '' : 'authorSection.written',
            isDisabledAuthor: author?.isDisabledAuthor,
          },
          {
            name: reviewerName,
            prefix: isEmpty(reviewerName) ? '' : 'authorSection.reviewed',
          },
        ];

  return (
    <div className="h-full overflow-hidden" id="blog-side-banners-col">
      <BlogAuthorName
        blogPageType={blogPageType}
        enBlogPath={enBlogPath}
        authorData={authorData}
        modifiedAt={modifiedAt}
        timeToRead={timeToRead}
        ns={`pages${enBlogPath || '/'}$article/content`}
        className={articleAuthorStyles({
          blogPageType,
        })}
      />
      <div id="blog-side-banners">
        {showSubscribeBanner && (
          <BlogSubscribeBanner
            className="mt-[640px] border-t border-shade-30 pt-6 hidden"
            subscriptionId={subscription?.settings?.subscriptionId}
            enBlogPath={enBlogPath}
            blogPageType={blogPageType}
          />
        )}
        {popularPosts.length > 0 && (
          <PopularPostsBanner
            blogPageType={blogPageType}
            storefrontArticles={popularPosts.slice(
              0,
              Math.round(popularPosts.length / 2),
            )}
            enBlogPath={enBlogPath}
            className="mt-[640px]"
          />
        )}
        {showSideBanner && (
          <BlogSideBanner
            className="bg-[#EEFAB3] mt-[640px] hidden"
            title={t('rightColSideBannerTitle')}
            buttonText={
              blogPageType === BLOG_PAGE_TYPE.ENTERPRISE
                ? t('rightColSideBannerButtonText')
                : signupText
            }
            buttonHint={t('rightColSideBannerButtonHint')}
            href={getRightBannerButtonUrl({
              blogPageType,
              signupUrl,
              localizePath,
            })}
            imageSrc={images.rightColSideBannerImg}
            imageAlt={signupName}
            imageWidthPadding
            titleStyle={sideBannerTitleStyle}
            blogPageType={blogPageType}
          />
        )}
        {popularPosts.length > 1 && (
          <PopularPostsBanner
            blogPageType={blogPageType}
            storefrontArticles={popularPosts.slice(
              Math.round(popularPosts.length / 2),
            )}
            enBlogPath={enBlogPath}
            className="mt-[640px]"
          />
        )}
      </div>
    </div>
  );
};
