import isEmpty from 'lodash/isEmpty';

import {BLOG_PAGE_TYPE} from '@/components/blog/types';
import type {Article} from '@/components/blog/types';
import Typography from '@/components/base/elements/Typography/Typography';
import Image from '@/components/base/elements/Image/Image';
import {useTranslations} from '@/hooks/useTranslations';
import {topicEnPath, filterArticleTag} from '@/components/pages/blog/utils';
import {useShowArticleHeroImage} from '@/hooks/experiments/useShowArticleHeroImage';
import {getMetafieldAuthors} from '@/pages/shopify.com/($locale)/enterprise/blog/utils/utils';
import {BlogAuthorName} from '@/components/pages/blog/BlogAuthor/BlogAuthorName';

import {
  articleAuthorStyles,
  blogArticleHeroContainerStyles,
  blogArticleContainerLinksStyles,
  blogArticleTitleStyles,
  blogArticleDescriptionStyles,
  blogTopicLinkStyles,
} from './style';

interface BlogArticleHeaderProps {
  article: Article;
  reviewerName?: string;
  enBlogPath: string;
  blogLinkText: string;
  modifiedAt?: string;
  blogType: BLOG_PAGE_TYPE;
}

export default function BlogArticleHero({
  article,
  reviewerName,
  enBlogPath,
  blogLinkText,
  blogType,
}: BlogArticleHeaderProps) {
  const {localizePath} = useTranslations();
  const articleTag = filterArticleTag(article.tags);
  const authorName = article.authorV2?.name;
  const isImageVisible = useShowArticleHeroImage(
    article.imageUrl,
    article.imageClass,
    blogType,
  );
  const isEnterpriseBlog = blogType === BLOG_PAGE_TYPE.ENTERPRISE;
  const enterpriseAuthors = getMetafieldAuthors(
    article.metafields || [],
    authorName,
  );

  const authorData =
    isEnterpriseBlog && enterpriseAuthors
      ? enterpriseAuthors
      : [
          {
            name: authorName,
            prefix: isEmpty(reviewerName) ? '' : 'authorSection.written',
          },
          {
            name: reviewerName,
            prefix: isEmpty(reviewerName) ? '' : 'authorSection.reviewed',
          },
        ];

  return (
    <div className="relative">
      <div
        className={blogArticleHeroContainerStyles({isImageVisible})}
        data-mode="light"
      >
        <div className="container desktop:w-9/12 desktop:mx-auto">
          <div
            className={blogArticleContainerLinksStyles({
              isImageVisible,
              blogType,
            })}
          >
            <a
              href={localizePath(enBlogPath)}
              className={blogTopicLinkStyles({blogType})}
            >
              {blogLinkText}
            </a>
            {articleTag && (
              <>
                <Typography as="span" className="px-2.5 text-xs">
                  |
                </Typography>
                <a
                  href={localizePath(
                    isEnterpriseBlog
                      ? `${enBlogPath}topics/${article.topicHandle}`
                      : topicEnPath(enBlogPath, articleTag),
                  )}
                  className={blogTopicLinkStyles({blogType})}
                >
                  {articleTag}
                </a>
              </>
            )}
          </div>
          <Typography
            as="h1"
            className={blogArticleTitleStyles({blogType})}
            itemProp="headline"
          >
            {article.title}
          </Typography>
          {(article.excerpt || article.seo?.description) && (
            <Typography
              as="p"
              className={blogArticleDescriptionStyles({
                isImageVisible,
                blogType,
              })}
            >
              {article.excerpt || article.seo?.description}
            </Typography>
          )}
          <div className="lg:hidden mt-4 tablet-xl:mt-12">
            <BlogAuthorName
              blogPageType={blogType}
              enBlogPath={enBlogPath}
              authorData={authorData}
              modifiedAt={isEnterpriseBlog ? '' : article.modifiedAt}
              ns={`pages${enBlogPath}$article/content`}
              className={articleAuthorStyles({
                blogType,
              })}
            />
          </div>
        </div>
      </div>
      {isImageVisible && (
        <div className="tablet:container desktop:w-9/12 desktop:mx-auto overflow-hidden flex justify-center">
          <Image
            src={article.imageUrl || ''}
            alt={article.imageAltText || ''}
            className={article.imageClass || ''}
          />
        </div>
      )}
    </div>
  );
}
