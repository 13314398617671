import {useLoaderData} from '@remix-run/react';

import ArrowRight from '@/components/blog/icons/ArrowRight';
import {useSharedTranslations} from '@/hooks/useTranslations';
import {useBlogArticleCtaTextsExp} from '@/hooks/experiments/useBlogArticleCtaTextExp/useBlogArticleCtaTextsExp';
import {useTailoredMessagingTopThreeCTAs} from '@/hooks/experiments/useTailoredMessagingTopThreeCTAs';
import {getPageCluster} from '@/components/blog/utils';
import type {ShortcodeTransformer} from '@/components/blog/BlogArticleContent/BlogArticleRenderer/types';

import {linkStyles, wrapperStyles} from './styles';

export const blogDataTextCtaTransformer: ShortcodeTransformer = {
  selector: '#text-cta',
  transformation: (node: Element) => {
    return {
      component: <TextCta />,
      node,
    };
  },
};

function TextCta() {
  const data = useLoaderData<any>() ?? {};
  const blogPageType = data.blogHandle;
  const {t} = useSharedTranslations(`pages${data.fileRoutePath}/content`);
  const {t: tCTAs} = useSharedTranslations(`pages/blog/$article/ctas`);
  const ctaVariant = useBlogArticleCtaTextsExp(blogPageType);
  const hasTailoredMessaging = useTailoredMessagingTopThreeCTAs();
  const tailoredCTAMessages =
    hasTailoredMessaging &&
    data?.pageCluster &&
    getPageCluster(
      tCTAs(`textCta.${hasTailoredMessaging}.clusters`),
      data.pageCluster,
    );

  const {href} = t('textCta');
  const {text} =
    tailoredCTAMessages || t(`textCta.${ctaVariant}`) || t('textCta');

  return (
    <div className={wrapperStyles({blogType: blogPageType})}>
      <a className={linkStyles({blogType: blogPageType})} href={href}>
        {text}
        <span className="bg-black rounded-full flex justify-center items-center w-6 h-6 ml-4 my-auto [&_svg]:w-6 group-hover:translate-x-4 group-hover:opacity-0 duration-500 will-change-transform">
          <ArrowRight />
        </span>
      </a>
    </div>
  );
}
