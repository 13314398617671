import {EXP_PRESSINGLY_WEARY_CREEPER} from '@/experiments';
import {useSiteData} from '@/hooks/useSiteData';

import {useExperiment} from '../../useExperiment';

export const usePressinglyWearyCreeper = () => {
  const expValue = useExperiment(EXP_PRESSINGLY_WEARY_CREEPER);
  const {site} = useSiteData();

  return site.locale === 'en' ? expValue : 'control';
};
