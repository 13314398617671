import Typography from '@/components/base/elements/Typography/Typography';
import SubscribeForm from '@/components/pages/blog/SubscribeForm/SubscribeForm';
import {useSharedTranslations} from '@/hooks/useTranslations';
import {twMerge} from '@/stylesheets/twMerge';
import {BLOG_PAGE_TYPE} from '@/components/blog/types';

import {inputWrapperStyles, bannerTitleStyles} from './style';

interface IBlogSubscribeBannerProps {
  className?: string;
  subscriptionId: string;
  enBlogPath: string;
  blogPageType: BLOG_PAGE_TYPE;
}

export const BlogSubscribeBanner = ({
  className,
  subscriptionId,
  enBlogPath,
  blogPageType,
}: IBlogSubscribeBannerProps) => {
  const {t} = useSharedTranslations(`pages${enBlogPath}$article/content`);
  const isEnterpriseBlog = blogPageType === BLOG_PAGE_TYPE.ENTERPRISE;

  return (
    <div className={className} data-priority="2">
      <Typography
        className={bannerTitleStyles({
          isEnterpriseBlog,
        })}
      >
        {t('subscribeBanner.title')}
      </Typography>
      <Typography
        className={twMerge(
          'text-sm mb-4',
          isEnterpriseBlog ? 'font-shopifysans' : '',
        )}
      >
        {t('subscribeBanner.description')}
      </Typography>
      <SubscribeForm
        {...t('subscribeBanner')}
        inputWrapperClass={inputWrapperStyles}
        subscriptionId={subscriptionId}
        blogType={blogPageType}
      />
      <Typography
        className={twMerge(
          'text-xs mt-2 text-shade-60',
          isEnterpriseBlog ? 'font-shopifysans' : '',
        )}
      >
        {t('subscribeBanner.hint')}
      </Typography>
    </div>
  );
};
