import type {BLOG_PAGE_TYPE} from '@/components/blog/types';

import type {CtaExperimentsMap} from './types';
import {useRetailMainCtasCopies} from './useRetailMainCtasCopies';
import {usePressinglyWearyCreeper} from './usePressinglyWearyCreeper';

const useExpByBlogPageType: CtaExperimentsMap = {
  blog: usePressinglyWearyCreeper,
  retail: useRetailMainCtasCopies,
};

export const useBlogArticleCtaTextsExp = (blogPageType: BLOG_PAGE_TYPE) => {
  const experiment = useExpByBlogPageType[blogPageType];

  if (!experiment) return 'control';

  return experiment();
};
