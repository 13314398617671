import {useExperiment} from '@/hooks/useExperiment';
import {
  EXP_FEATURED_IMAGE_REMOVAL_BLOG,
  EXP_FEATURED_IMAGE_REMOVAL_CORE_BLOG,
} from '@/experiments';
import {useSiteData} from '@/hooks/useSiteData';
import type {Article} from '@/components/blog/types';
import {BLOG_PAGE_TYPE} from '@/components/blog/types';

export const LOCALES_FOR_EXPERIMENT = ['en-GB', 'en-AU', 'en-CA'];

export const useShowArticleHeroImage = (
  imageUrl: Article['imageUrl'],
  imageClass: Article['imageClass'],
  blogType: BLOG_PAGE_TYPE,
): boolean => {
  const {site} = useSiteData();
  const isFeaturedImageHiddenVariant =
    useExperiment(EXP_FEATURED_IMAGE_REMOVAL_BLOG) === 'treatment';
  const isFeaturedImageHiddenForEnLocaleVariant =
    useExperiment(EXP_FEATURED_IMAGE_REMOVAL_CORE_BLOG) === 'treatment';
  const isImageExistAndVisible = imageUrl && !imageClass?.includes('hidden');
  const isEngineeringBlog = blogType === BLOG_PAGE_TYPE.ENGINEERING;

  if (!isImageExistAndVisible || isEngineeringBlog) {
    return false;
  }

  const isLocaleUsedInExperiment = LOCALES_FOR_EXPERIMENT.includes(site.locale);
  const isImageHiddenByExperiment =
    isFeaturedImageHiddenVariant &&
    isLocaleUsedInExperiment &&
    blogType === BLOG_PAGE_TYPE.BLOG;

  const isImageHiddenByExperimentForEnLocale =
    isFeaturedImageHiddenForEnLocaleVariant &&
    site.locale === 'en' &&
    blogType === BLOG_PAGE_TYPE.BLOG;

  return !(isImageHiddenByExperiment || isImageHiddenByExperimentForEnLocale);
};
