import {useEffect, useState} from 'react';
import {createPortal} from 'react-dom';

import {
  blogDataGuideTransformer,
  blogDataSubscribeTransformer,
  blogDataTextCtaTransformer,
  blogTruncatedSectionTransformer,
} from './shortcodes';
import type {ComponentToNode} from './types';

/**
 * This component is responsible for rendering the blog article content
 * and replacing any shortcodes with their respective components by using
 * React Portals. This requires the "shortcode" to provide a transformation
 * function that conforms to the ShortcodeTransformer interface, which maps a
 * selector to a component, giving you an opportunity to pass in any props.
 */
export function BlogArticleRenderer({content}: {content: string}) {
  const [shortcodes, setShortcodes] = useState<ComponentToNode[]>([]);

  useEffect(() => {
    const componentSwaps: ComponentToNode[] = [];
    const supportedShortcodes = [
      blogDataGuideTransformer,
      blogDataSubscribeTransformer,
      blogDataTextCtaTransformer,
      blogTruncatedSectionTransformer,
    ];

    // find all the selectors for our shortcodes
    supportedShortcodes.forEach(({selector, transformation}) => {
      const swaps = Array.from(document.querySelectorAll(selector))
        .map(transformation)
        .filter(Boolean) as ComponentToNode[];

      componentSwaps.push(...swaps);
    });

    setShortcodes(componentSwaps);
  }, [content]);

  return (
    <>
      {/* NOTE: Do not remove the following <div>
      Remix uses only styles/classNames that are present in views while CSS is being compiled.
      So, when article page is being loaded Remix doesn't know about classNames in article content.
      It means that any styles/classNames described in blog.css won't be applied to the content in BlogArticleRenderer
      if the classNames weren't used in *.tsx files.
      */}
      <div className="hidden leadpage-container leadpage__image leadpage__content marketing-button hide--mobile block--bordered"></div>
      <div dangerouslySetInnerHTML={{__html: content}} />
      {shortcodes.length > 0 &&
        shortcodes.map(({component, node}) => {
          return createPortal(component, node);
        })}
    </>
  );
}
